import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../components/seo';
import Posts from '../components/sections/Posts';

import PaulImg from '../assets/paul.jpg'

const postsToFeature = [
  'what-is-verifiable-computing',
  'experiments-features-platforms',
  'permissionless-public-infrastructure',
  'scaling-meetings',
  'high-performing-team',
  'remote-pair-programming',
];

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  const featuredPosts = posts.filter(
    post => postsToFeature.indexOf(post.node.frontmatter.id) > -1,
  );
  return (
    <Container>
      <SEO title="Home" />
      <TopSection>
        <img src={PaulImg} alt="profile photo" style={{ 
          borderRadius: '50%',
          width: '40%',
          // display: 'block',
          // marginLeft: 'auto',
          // marginRight: 'auto',
          }}
        />
        <p>
          I build technical products and teams.
        </p>
        <p>
          I am enabling trustworthy AI through cryptographically verifiable computing at <a href="https://www.eqtylab.io/">EQTY Lab</a>.
          Previously I led <a href="https://blockchain.shopify.dev/">Blockchain engineering</a> at <a href="https://www.shopify.com/">Shopify</a>, 
          and I led engineering for user-facing apps at <a href="https://polygon.technology/">Polygon</a>.
        </p>
        <p>
          I like adventures and I like solving problems.
          I have built many products, led software development teams and organizations, led technology at a startup with a successful exit, founded and operated <Link to="/posts/shutting-down-okgrow">a successful company</Link> for 8 years, and helped lead and scale one of the fastest-growing large engineering organizations in the world at Shopify.
          I have created popular open source projects, organized conferences with hundreds of people, built a successful training program, and built trust with all whom I have worked with.
        </p>
        <p>
          I love to talk about software engineering and building great products, the business of technology, and anything related to decentralized infrastructure and blockchain tech. If you're working on similar things, or have an interesting technology, process, or culture problem/opportunity send me a message and let's talk.
        </p>
      </TopSection>
      <Links>
        <a href="https://twitter.com/pauldowman">Twitter</a>
        <a href="https://www.linkedin.com/in/pdowman/">LinkedIn</a>
        <a href="https://www.farcaster.id/pauldowman">Farcaster</a>
        <a href="mailto:paul@pauldowman.com">Email</a>
        <a href="https://t.me/pauldowman">Telegram</a>
      </Links>
      <Links>
        <a href="https://github.com/pauldowman">GitHub</a>
        <a href="https://www.youtube.com/channel/UCemC4xuL1cZhVlixuoEjtSg/videos">YouTube</a>
        <a href="/pgpkey.txt">PGP</a>
        <a href="https://rainbow.me/pauldowman.eth">Ethereum</a>
      </Links>
      <LatestPosts>
        <h2>Featured Writing</h2>
        <AllPostsLink to="posts">All Writing</AllPostsLink>
        <Posts posts={featuredPosts} />
        <Link to="posts">See more writing...</Link>
      </LatestPosts>
    </Container>
  );
};

const TopSection = styled.div`
  width: 66%;
  padding-right: 48px;
  a {
    color: white;
    border-bottom: 1px solid white;
  }
  @media (max-width: 666px) {
    width: 100%;
    padding-right: 0;
  }
`;

const LatestPosts = styled.div`
  margin-top: 80px;
  padding-bottom: 40px;
  border-bottom: 2px solid white;
  position: relative;
`;

const AllPostsLink = styled(Link)`
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  line-height: 20px;
  border-color: white;
  opacity: 0.5;
  border-bottom: 2px solid white;
  transition: opacity 150ms ease-in;
  &:hover {
    opacity: 1;
  }
`;

const Links = styled.div`
  a {
    color: #0093ff;
    margin-right: 32px;
    font-size 16px;
  }
`;

const Container = styled.div`
  p {
    font-size: 16px;
    font-weight: 300;
  }
`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            id
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;

export default IndexPage;
